import React, { useEffect } from "react";
import Map from "./components/map";
import { connect } from "react-redux";
import {
  fetchLanguageData as _fetchLanguageData,
  selectArcs,
  selectActiveWordEtymology,
  setIsMobile as _setIsMobile,
} from "./ducks";
import SearchArea from "./components/search-area";
import styles from "./App.module.scss";
import useMediaQuery from "./hooks/useMediaQuery";
// import useWindowSize from "./hooks/useWindowSize";

const Header = () => {
  return (
    <div className={styles.appHeader}>
      <h2>
        Some loan words in Aboriginal Languages, <br />
        pre-1788
      </h2>
    </div>
  );
};

function App({ fetchLanguageData, setIsMobile, arcs, activeWordEtymology }) {
  const { isMobile, isTablet } = useMediaQuery();
  // const { height: windowHeight } = useWindowSize();

  useEffect(() => {
    fetchLanguageData();
  }, [fetchLanguageData]);

  // Sometimes 'isMobile' is needed for global selectors
  useEffect(() => {
    setIsMobile(isMobile);
  }, [isMobile, setIsMobile]);

  return (
    <div className={`${styles.App}`}>
      {isMobile ? (
        <>
          <Header />
          {arcs && (
            <Map
              arcs={arcs}
              isMobile={isMobile}
              isTablet={isTablet}
              activeWordEtymology={activeWordEtymology}
            />
          )}
          <SearchArea />
        </>
      ) : (
        <>
          {arcs && (
            <Map
              arcs={arcs}
              isMobile={isMobile}
              activeWordEtymology={activeWordEtymology}
            />
          )}
          <SearchArea header={<Header />} />
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    arcs: selectArcs(state),
    activeWordEtymology: selectActiveWordEtymology(state),
  };
};

const mapDispatchToProps = {
  fetchLanguageData: _fetchLanguageData,
  setIsMobile: _setIsMobile,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
