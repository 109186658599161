import React, { useEffect } from "react";
import { StaticMap, Source, Layer } from "react-map-gl";
import styles from "./map.module.scss";
import "./map.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { connect } from "react-redux";
import { selectSourceLanguages, selectActiveWord } from "../../ducks";
import useDeckViewport from "../../hooks/useDeckViewport";
import { DeckGL, MapView } from "deck.gl";
import { ScatterplotLayer, ArcLayer } from "@deck.gl/layers";
import { getArcArrayBounds, arcsToLatLongArray } from "./map-helpers";

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
const MAPBOX_BASEMAP_STYLE_ID =
  process.env.REACT_APP_MAPBOX_STYLE ||
  "mapbox://styles/smallmultiples/ckghc7e0021tq19pblu5jpdu0";

// I'm sure I don't need this
const initialBounds = [
  [-9.1399, -12.451353],
  [135.811, 52.43352],
];

const languageLabelLayer = {
  id: "points",
  type: "symbol",
  layout: {
    // Get the title name from the source's "title" property
    "text-field": ["get", "title"],
    "text-size": 17,
    "text-font": [
      "Arial Unicode MS Regular",
      "Source Serif Pro Bold",
      "Open Sans Condensed Bold",
    ],
    "text-radial-offset": ["get", "labelOffset"],
    "text-anchor": ["get", "textLabelAnchor"],
  },
  paint: {
    "text-halo-width": 2,
    "text-halo-color": "rgba(255, 255, 255, 0.7)",
  },
};

const Map = ({
  arcs,
  languages,
  isMobile,
  isTablet,
  activeWordEtymology,
  activeWord,
}) => {
  const arcPadding = isMobile ? 55 : isTablet ? 50 : 100;

  const {
    mapContainerRef,
    viewport,
    handleViewStateChange,
    handleBoundsChange,
  } = useDeckViewport(initialBounds, arcPadding);

  // useEffect(() => {
  //   console.log(viewport ? viewport.zoom : "no viewport");
  // }, [viewport]);

  // When arcs change, update viewport
  useEffect(() => {
    if (arcs && arcs.length) {
      const latLongArray = arcsToLatLongArray(arcs);
      const newBounds = getArcArrayBounds(latLongArray);
      handleBoundsChange(newBounds);
    }
  }, [arcs, handleBoundsChange]);

  const data = arcs.map((arc) => {
    return {
      from: {
        name: arc.origin.name,
        coordinates: [arc.origin.long, arc.origin.lat],
      },
      to: {
        name: arc.destination.name,
        coordinates: [arc.destination.long, arc.destination.lat],
      },
      words: arc.words,
    };
  });

  const dotData = activeWordEtymology
    ? activeWordEtymology.steps.reduce((acc, curr) => {
        return [...acc, ...curr];
      }, [])
    : [];

  const dotLabels = dotData.map((d) => d.language);

  const geojson = activeWordEtymology.steps.length
    ? {
        type: "FeatureCollection",
        features: dotData.map((label) => {
          const { language: l, word: w } = label;

          return {
            type: "Feature",
            geometry: { type: "Point", coordinates: [l.long, l.lat] },
            name: l.name,
            coordinates: [l.long, l.lat],
            properties: {
              title: `${l.name}\n(${w.word})`,
              textLabelAnchor: isMobile
                ? w.textLabelAnchorMobile
                : w.textLabelAnchor,
              labelOffset: isMobile ? w.labelOffsetMobile : w.labelOffset,
            },
          };
        }),
      }
    : {
        type: "FeatureCollection",
        features: languages
          .filter(
            (l) => l.displayOnOpening && viewport && viewport.zoom >= l.minZoom
          )
          .map((l) => {
            return {
              type: "Feature",
              geometry: { type: "Point", coordinates: [l.long, l.lat] },
              name: l.name,
              coordinates: [l.long, l.lat],
              properties: {
                title: `${l.name}`,
                textLabelAnchor: isMobile
                  ? l.textLabelAnchorMobile
                  : l.textLabelAnchor,
                labelOffset: isMobile ? l.labelOffsetMobile : l.labelOffset,
              },
            };
          }),
      };

  // Dots at the ends of arcs
  const dotSource = activeWordEtymology.steps.length ? dotLabels : languages;
  const langData = dotSource.map((l) => {
    return {
      name: l.name,
      coordinates: [l.long, l.lat],
    };
  });

  const layers = [
    new ArcLayer({
      id: "arc-layer",
      data: data,
      pickable: true,
      getWidth: (d) => 7 + d.words.length * 0.5,
      getHeight: 0.6,
      getSourcePosition: (d) => d.from.coordinates,
      getTargetPosition: (d) => d.to.coordinates,
      getSourceColor: (d) => [249, 157, 28],
      getTargetColor: (d) => [226, 74, 33],
      getTilt: (d) => {
        if (!d) return 0;
        return d.from.coordinates[0] > d.to.coordinates[0] ? -9 : 7;
      },
    }),
    // Language circles
    new ScatterplotLayer({
      id: "scatterplot-layer",
      data: langData,
      pickable: true,
      opacity: 0.8,
      stroked: true,
      filled: true,
      radiusScale: 60,
      radiusMinPixels: 1,
      radiusMaxPixels: 500,
      lineWidthMinPixels: 1,
      getPosition: (d) => d.coordinates,
      getRadius: (d) => {
        return 500 - viewport.zoom * 50;
      },
      getFillColor: (d) => [255, 140, 0],
      getLineColor: (d) => [0, 0, 0],
    }),
  ];

  return (
    <div className={styles.mapContainer} style={{ position: "relative" }}>
      {activeWord && (
        <div
          style={{
            position: "absolute",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 2,
            height: 20,
            width: 65,
            top: 15,
            left: isMobile ? "unset" : 15,
            right: isMobile ? 20 : "unset",
            padding: `5px 10px`,
            borderRadius: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "15px",
            fontWeight: 700,
          }}
        >
          {activeWord}
        </div>
      )}
      <div
        ref={mapContainerRef}
        style={{ position: "relative", width: "100%", height: "100%" }}
      >
        {viewport && (
          <DeckGL
            viewState={viewport}
            layers={layers}
            onViewStateChange={handleViewStateChange}
            controller={true}
            views={new MapView({ repeat: true })}
            getTooltip={({ object, layer }) => {
              if (layer && layer.id === "scatterplot-layer") {
                return object && object.name;
              }
              return (
                object &&
                object.from &&
                `${object.from.name} to ${object.to.name}`
              );
            }}
          >
            <StaticMap
              mapboxApiAccessToken={MAPBOX_TOKEN}
              mapStyle={MAPBOX_BASEMAP_STYLE_ID}
            >
              <Source id="my-data" type="geojson" data={geojson}>
                <Layer {...languageLabelLayer} />
              </Source>
            </StaticMap>
          </DeckGL>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    languages: selectSourceLanguages(state),
    activeWord: selectActiveWord(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
