import React from "react";
import styles from "./word-details.module.scss";
import { connect } from "react-redux";
import { selectActiveWordEtymology } from "../../ducks";
import { WordCard, SourceCard } from "../word-card";
import { SortDownIcon } from "../icons";

const Arrow = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        margin: `0px`,
        color: "rgba(255, 255, 255, 0.8)",
      }}
    >
      <SortDownIcon height={20} width={20} />
    </div>
  );
};

const WordDetails = ({ activeWordEtymology }) => {
  const { steps, sources } = activeWordEtymology;

  return (
    <div className={styles.wordDetails}>
      {steps.map((card, i) => {
        return (
          <div key={i}>
            <WordCard details={card} key={i} />
            {i < steps.length - 1 && <Arrow />}
          </div>
        );
      })}
      <SourceCard sources={sources} steps={steps} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    activeWordEtymology: selectActiveWordEtymology(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WordDetails);
