import React from "react";
import styles from "./search-area.module.scss";
import Filters from "../filters";
import { connect } from "react-redux";
import {
  selectFilters,
  selectActiveWord,
  toggleFilter as _toggleFilter,
} from "../../ducks";
import WordDetails from "../word-details";

const Introduction = () => {
  return (
    <div className={styles.introduction}>
      <p>Click on a word above to see its etymology.</p>
      <p>
        <span>These etymologies are based on </span>
        <b>
          <a href="https://researchers.anu.edu.au/researchers/evans-nrd">
            Nicholas Evans'
          </a>{" "}
          <a href="https://www.researchgate.net/publication/232837254_Macassan_Loanwords_in_Top_End_Languages">
            Macassan Loanwords in Top End Languages
          </a>
        </b>{" "}
        (1992). Evans' phonetic spelling of Aboriginal words has been changed to
        reflect the spelling in current online dictionaries. Some earlier
        segments of etymology have been added from online sources and noted.
      </p>
      <p>
        <b>This is not a complete list of loan words</b>, nor of Aboriginal
        languages with overseas loan words. The selection of Aboriginal
        languages here reflects those languages covered by Evans that have large
        online dictionaries for cross-checking.
      </p>
      <p>
        Makassarese words use Evans' phonetic spelling, although we have
        replaced the glottal stop character "?" with an apostrophe (') to avoid
        confusion. This spelling convention is also used in contemporary Yolngu.
      </p>
      <p>
        Arcs may not represent a direct influence, in many cases there may have
        been intermediate languages. Many Macassan words in the Tiwi language
        for example may have come via the Iwaidja language.
      </p>
    </div>
  );
};

const Logo = () => {
  return (
    <div className={styles.logo}>
      <span style={{ fontSize: 14, marginRight: 10 }}>Map by </span>
      <a
        href="https://smallmultiples.com.au"
        style={{ borderBottomWidth: 0, height: 38 }}
      >
        <img
          src={`${process.env.REACT_APP_PUBLIC_URL}images/logo.png`}
          alt="Small Multiples logo"
          className={`${styles.logoImg}`}
        />
      </a>
    </div>
  );
};

const SearchArea = ({ activeWord, header }) => {
  return (
    <div className={styles.searchArea}>
      <div className={styles.searchAreaInner}>
        <div className={styles.searchAreaWithoutLogo}>
          {header && <>{header}</>}
          <div className={styles.filtersWrapper}>
            <Filters />
          </div>
          <div className={styles.textWrapper}>
            {activeWord ? <WordDetails /> : <Introduction />}
          </div>
        </div>
      </div>
      <div className={styles.logoArea}>
        <Logo />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    filters: selectFilters(state),
    activeWord: selectActiveWord(state),
  };
};

const mapDispatchToProps = {
  toggleFilter: _toggleFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchArea);
