import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import { Provider as StoreProvider } from "react-redux";

window.smuEmbedMap = function (element) {
  const el =
    element instanceof HTMLElement ? element : document.querySelector(element);
  const render = () =>
    ReactDOM.render(
      <React.StrictMode>
        <StoreProvider store={store}>
          <App />
        </StoreProvider>
      </React.StrictMode>,
      el
    );
  render();
  if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("./App", render);
  }
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
