import React from "react";
import styles from "./filters.module.scss";
import { connect } from "react-redux";
import { toggleFilter as _toggleFilter, selectFilters } from "../../ducks";
import { TickIcon } from "../icons";

const FilterButton = ({ active, toggleFilter, filter, label }) => {
  const activeClass = active ? styles.active : styles.inactive;

  return (
    <button
      className={`${styles.filterButton} ${activeClass}`}
      onClick={() => {
        toggleFilter(filter.wordId);
      }}
    >
      <div className={styles.filterButtonInner}>
        {active && (
          <div className={styles.tickWrapper}>
            <TickIcon />
          </div>
        )}
        <span>{label}</span>
      </div>
    </button>
  );
};

const Filters = ({ filters, toggleFilter }) => {
  return (
    <div className={styles.filterButtonArea}>
      {filters && (
        <div style={{ width: "100%" }}>
          {filters.map((w, i) => {
            return (
              <FilterButton
                key={i}
                toggleFilter={toggleFilter}
                active={w.active}
                filter={w}
                label={w.wordLabel}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    filters: selectFilters(state),
  };
};

const mapDispatchToProps = {
  toggleFilter: _toggleFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
