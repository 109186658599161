import { getLanguageData } from "../api";

const initialState = {
  data: {
    sourceLanguages: [],
    arcs: [],
    words: [],
    definitions: [],
  },
  ui: {
    filters: [],
    activeCommunity: "",
    hoveredCommunity: "",
    languageValue: null,
    communityValue: null,
    communityInputValue: "",
    languageInputValue: "",
    isMobile: false,

    // Etymolgy
    activeLanguage: null,
    activeWord: "",
  },
};
const actionTypes = {
  DATA_REQUEST: "DATA_REQUEST",
  SET_PROJECT_DATA: "PROJECT_DATA:SET",
  DATA_ERROR: "DATA_ERROR",
  SET_ACTIVE_FILTERS: "SET_ACTIVE_FILTERS",
  TOGGLE_FILTER: "TOGGLE_FILTER",
  SET_ACTIVE_COMMUNITY: "SET_ACTIVE_COMMUNITY",
  SET_ACTIVE_LANGUAGE: "SET_ACTIVE_LANGUAGE",
  SET_ACTIVE_WORD: "SET_ACTIVE_WORD",
  SET_ACTIVE_COMMUNITY_BY_ID: "SET_ACTIVE_COMMUNITY_BY_ID",
  SET_ACTIVE_LANGUAGE_BY_ID: "SET_ACTIVE_LANGUAGE_BY_ID",
  SET_LANGUAGE_VALUE: "SET_LANGUAGE_VALUE",
  SET_COMMUNITY_VALUE: "SET_COMMUNITY_VALUE",
  SET_COMMUNITY_INPUT_VALUE: "SET_COMMUNITY_INPUT_VALUE",
  SET_LANGUAGE_INPUT_VALUE: "SET_LANGUAGE_INPUT_VALUE",
  SET_HOVERED_COMMUNITY: "SET_HOVERED_COMMUNITY",
  SET_IS_MOBILE: "SET_IS_MOBILE",

  // ETY
  LANGUAGE_DATA_SUCCESS: "LANGUAGE_DATA_SUCCESS",
  LANGUAGE_DATA_ERROR: "LANGUAGE_DATA_ERROR",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LANGUAGE_INPUT_VALUE:
      return {
        ...state,
        ui: {
          ...state.ui,
          languageInputValue: action.payload,
        },
      };
    case actionTypes.SET_LANGUAGE_VALUE:
      return {
        ...state,
        ui: {
          ...state.ui,
          languageValue: action.payload,
        },
      };
    case actionTypes.SET_IS_MOBILE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isMobile: action.payload,
        },
      };
    case actionTypes.LANGUAGE_DATA_SUCCESS:
      const languageData = action.payload;
      return {
        ...state,
        data: { ...state.data, ...languageData },
        ui: {
          ...state.ui,
          filters: languageData.words,
        },
      };
    case actionTypes.DATA_ERROR:
      console.log("Error: ", action.payload);
      return state;

    case actionTypes.LANGUAGE_DATA_ERROR:
      console.log("Error: ", action.payload);
      return state;
    case actionTypes.SET_ACTIVE_COMMUNITY:
      return {
        ...state,
        ui: {
          ...state.ui,
          activeCommunity: action.payload,
        },
      };
    // ETYMOLOGY
    case actionTypes.SET_ACTIVE_WORD:
      return {
        ...state,
        ui: {
          ...state.ui,
          activeWord: action.payload,
        },
      };

    case actionTypes.TOGGLE_FILTER:
      const filterId = action.payload;
      const filters = state.ui.filters;
      const activeFilters = filters.filter((f) => f.active);
      const togglingOffLastFilter =
        activeFilters.length === 1 &&
        activeFilters[0].wordId === action.payload;

      if (togglingOffLastFilter) {
        return {
          ...state,
          ui: {
            ...state.ui,
            filters: filters.map((f) => {
              return {
                ...f,
                active: true,
              };
            }),
            activeWord: "",
          },
        };
      } else {
        return {
          ...state,
          ui: {
            ...state.ui,
            filters: filters.map((f) => {
              return {
                ...f,
                active: f.wordId === filterId,
              };
            }),
            activeWord: action.payload,
          },
        };
      }
    default:
      return state;
  }
};

// Etymology
export const setActiveWord = (payload) => {
  return {
    type: actionTypes.SET_ACTIVE_WORD,
    payload: payload,
  };
};

// Actions
export const setActiveFilters = (payload) => {
  return {
    type: actionTypes.SET_ACTIVE_FILTERS,
    payload: payload,
  };
};

export const setIsMobile = (payload) => {
  return {
    type: actionTypes.SET_IS_MOBILE,
    payload: payload,
  };
};

export const setLanguageValue = (payload) => {
  return {
    type: actionTypes.SET_LANGUAGE_VALUE,
    payload: payload,
  };
};

export const setLanguageInputValue = (payload) => {
  return {
    type: actionTypes.SET_LANGUAGE_INPUT_VALUE,
    payload: payload,
  };
};

export const setActiveLanguageById = (id) => {
  return {
    type: actionTypes.SET_ACTIVE_LANGUAGE_BY_ID,
    payload: id,
  };
};

export const toggleFilter = (payload) => {
  return {
    type: actionTypes.TOGGLE_FILTER,
    payload: payload,
  };
};

// Simple selectors

export const selectActiveLanguage = (state) => {
  return state.ui.activeLanguage;
};

export const selectActiveWord = (state) => {
  return state.ui.activeWord;
};

export const selectLanguageValue = (state) => {
  return state.ui.languageValue;
};

export const selectIsMobile = (state) => {
  return state.ui.isMobile;
};

export const selectLanguageInputValue = (state) => {
  return state.ui.languageInputValue;
};

export const selectHoveredCommunity = (state) => {
  return state.ui.hoveredCommunity;
};

export const selectFilters = (state) => {
  return state.ui.filters;
};

export const selectSourceLanguages = (state) => {
  return state.data.sourceLanguages;
};

export const selectWords = (state) => {
  return state.data.words;
};

// Complex selectors
export const selectActiveWordEtymology = (state) => {
  const { arcs, definitions, sourceLanguages: languages } = state.data;
  const { activeWord } = state.ui;

  if (!activeWord) return { steps: [] };

  const activeWordArcs = arcs.filter((arc) => arc.wordId === activeWord);

  const arcsWithFullLanguages = activeWordArcs.map((arc) => {
    const { origin, destination } = arc;
    const originLang = languages.find((l) => l.id === origin);
    const destLang = languages.find((l) => l.id === destination);

    return {
      wordId: arc.wordId,
      origin: originLang,
      destination: destLang,
      order: arc.order,
    };
  });

  const wordSource = arcsWithFullLanguages.find((arc) => arc.order === 1);

  // Take the initial origin, then all the destinations
  const wordSourceCard = [
    {
      language: wordSource.origin,
      word: definitions.find(
        (d) =>
          d.wordId === wordSource.wordId &&
          d.languageId === wordSource.origin.id
      ),
    },
  ];

  const otherWords = arcsWithFullLanguages.reduce((acc, curr) => {
    const dest = curr.destination;
    const newCard = {
      language: dest,
      word: definitions.find(
        (d) => d.wordId === curr.wordId && d.languageId === dest.id
      ),
    };
    if (acc[curr.order]) {
      return {
        ...acc,
        [curr.order]: [...acc[curr.order], newCard],
      };
    } else {
      return {
        ...acc,
        [curr.order]: [newCard],
      };
    }
  }, {});

  const otherWordCards = Object.values(otherWords);
  const allWords = [wordSourceCard, ...otherWordCards];

  // Reduce sources to only unique ones
  const sources = Object.values(
    activeWordArcs.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.sourceName]: { name: curr.sourceName, href: curr.source },
      };
    }, {})
  );
  const sortedSources = sources.sort((a, b) => (a.name > b.name ? 1 : -1));

  return { steps: allWords, sources: sortedSources };
};

export const selectArcs = (state) => {
  const arcs = state.data.arcs;
  const activeFilters = state.ui.filters.filter((f) => f.active);
  const activeWordArcs = arcs.filter((arc) =>
    activeFilters.some((f) => f.wordId === arc.wordId)
  );

  const languages = state.data.sourceLanguages;

  const originDestinationMap = activeWordArcs.reduce((acc, arc) => {
    const { origin, destination } = arc;
    const key = `${origin}-${destination}`;

    const originLang = languages.find((l) => l.id === origin);
    const destLang = languages.find((l) => l.id === destination);

    if (acc[key]) {
      return {
        ...acc,
        [key]: { ...acc[key], words: [...acc[key].words, arc] },
      };
    } else {
      return {
        ...acc,
        [key]: {
          origin: originLang,
          destination: destLang,
          words: [arc],
        },
      };
    }
  }, {});

  const ret = Object.values(originDestinationMap);
  return ret;
};

export const fetchLanguageData = () => {
  return (dispatch) => {
    return getLanguageData()
      .then((response) => {
        dispatch({ type: "LANGUAGE_DATA_SUCCESS", payload: response });
      })
      .catch((err) => {
        dispatch({ type: "LANGUAGE_DATA_ERROR", payload: err });
      });
  };
};

export default reducer;
