// Modified and fixed version of @rehooks/component-size
// handles non-HTML elements properly (i.e. SVG)
import React from 'react';

const ResizeObserver = window.ResizeObserver;

function getSize(el) {
  if (!el) {
    return undefined;
  }

  const boundingRect = el.getBoundingClientRect();

  return {
    width: boundingRect.width,
    height: boundingRect.height,
    left: boundingRect.left,
    top: boundingRect.top,
  };
}

function useDimensions() {
  const ref = React.useRef(null);
  const target = ref.current;
  const [componentSize, setComponentSize] = React.useState(getSize(target));

  const handleResize = React.useCallback(() => {
    if (target) {
      setComponentSize(getSize(target));
    }
  }, [target]);

  // Manage listener for ResizeObserver (if avail) or window resize.
  React.useLayoutEffect(() => {
    if (!target) {
      return;
    }

    handleResize();

    if (typeof ResizeObserver === 'function') {
      const observeEl = target;
      let resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(observeEl);

      return () => {
        resizeObserver.disconnect(observeEl);
        resizeObserver = null;
      };
    } else {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [target, handleResize]);

  React.useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    setComponentSize(getSize(ref.current));
  }, [ref]);

  return [ref, componentSize];
}

export default useDimensions;
