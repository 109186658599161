import React from "react";
import styles from "./word-card.module.scss";

const WordLink = ({ word, href }) => {
  if (href && href.includes("http")) {
    return (
      <a href={href} target="blank" className={styles.featureWord}>
        {word}
      </a>
    );
  } else {
    return <span className={styles.featureWord}>{word}</span>;
  }
};

const CompareWord = ({ word }) => {
  return (
    <div>
      <span>Cf. {word.relatedOrigin}: </span>
      <WordLink word={word.relatedOriginWord} href={word.relatedOriginSource} />
      {word.relatedOriginOrthography && (
        <span> ({word.relatedOriginOrthography})</span>
      )}
    </div>
  );
};

export const WordCard = ({ details }) => {
  return (
    <div className={styles.wordCard}>
      {details.map((card, i) => {
        const { language, word } = card;
        return (
          <div key={i}>
            <span>{language.name}</span>
            <span>: </span>
            <WordLink word={word.word} href={word.source} />
            {word.orthography && (
              <span className={styles.orthography}> ({word.orthography})</span>
            )}
            {word.relatedOrigin && <CompareWord word={word} />}
          </div>
        );
      })}
    </div>
  );
};

export const SourceCard = ({ sources, steps }) => {
  const individualSteps = steps.reduce((acc, curr) => [...acc, ...curr], []);
  const hasQuestionChar = individualSteps.some((s) =>
    s.word.word.includes("'")
  );
  return (
    <div className={styles.wordCard} style={{ fontSize: 12, marginTop: 35 }}>
      <span>Source: </span>
      {sources.map((s, i) => {
        return (
          <span key={`source-${i}`}>
            <a href={s.href}>{s.name}</a>
            {i < sources.length - 1 && <span>, </span>}
          </span>
        );
      })}
      <span>. </span>
      {hasQuestionChar && (
        <span>
          The apostrophe (') character in Macassan and Yolngu words represents a
          glottal stop. Evans uses a "?" symbol for this sound.
        </span>
      )}
    </div>
  );
};
