const MAX_LAT = 90;
const MIN_LAT = -90;
const MAX_LONG = 180;
const MIN_LONG = -180;

export const arcsToLatLongArray = (arcs) => {
  return arcs.reduce((acc, curr) => {
    const { destination, origin } = curr;
    return [
      ...acc,
      { lat: destination.lat, long: destination.long },
      { lat: origin.lat, long: origin.long },
    ];
  }, []);
};

export const getArcArrayBounds = (latLongArray) => {
  const newBoundsObj = latLongArray.reduce(
    (acc, curr) => {
      const newMaxLat = curr.lat > acc.maxLat ? curr.lat : acc.maxLat;
      const newMaxLong = curr.long > acc.maxLong ? curr.long : acc.maxLong;
      const newMinLat = curr.lat < acc.minLat ? curr.lat : acc.minLat;
      const newMinLong = curr.long < acc.minLong ? curr.long : acc.minLong;

      return {
        maxLat: newMaxLat,
        maxLong: newMaxLong,
        minLat: newMinLat,
        minLong: newMinLong,
      };
    },
    {
      maxLat: MIN_LAT,
      maxLong: MIN_LONG,
      minLat: MAX_LAT,
      minLong: MAX_LONG,
    }
  );

  const newBounds = [
    [newBoundsObj.minLong, newBoundsObj.minLat],
    [newBoundsObj.maxLong, newBoundsObj.maxLat],
  ];

  return newBounds;
};
